.product-preview-container {
  cursor: pointer;
  height: 450px;
  width: 400px;
  padding-right: 25px;

  &.mobile {
    height: 350px;
    width: 300px;
  }

  &.small {
    height: 400px;
    width: 350px;
  }
  &.large {
    height: 500px;
    width: 450px;
  }

  .product-image-container {
    height: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.5);

    .image-side {
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }

  .product-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .product-details-top {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 10px 0;
      font-family: "Mate", serif;
      font-weight: 700;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.8);
    }

    .product-details-bottom {
      font-family: "Vollkorn SC", serif;
    }
  }
}
