.ambassador-program-page-container {
  padding: 100px 0;
  text-align: center;

  .ambassador-program-text {
    padding: 0 200px;
  }

  h1 {
    font-size: 30px;
    padding: 30px;
  }

  p {
    font-size: 20px;
  }

  .ambassador-perk {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .ambassador-perk-fontawesome {
      padding: 0 10px;
      font-size: 10px;
    }
  }

  .how-to-apply {
    padding: 20px 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;

    h1 {
      text-transform: uppercase;
    }

    p {
      padding: 30px;
      font-size: 20px;
    }
  }
}
