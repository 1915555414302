.popup-container {
  height: 600px;
  width: 800px;
  display: flex;
  background-color: white;

  .left-side {
    width: 50%;
    height: 100%;
    background-size: cover;
    // background-position-y: -200px;
    background-repeat: no-repeat;

    .herico-title {
      margin-top: 400px;
      margin-left: 70px;
      bottom: 0;
      font-size: 50px;
      font-weight: 700;
      color: rgba(41, 38, 38, 0.6);
    }
  }
  .right-side {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .right-side-text {
      margin-top: 75px;
      h3 {
        font-size: 20px;
        text-transform: uppercase;
      }
      h1 {
        font-size: 30px;
        text-transform: uppercase;
        padding: 20px;
      }
    }

    .email-input {
      padding: 50px;

      .submit-container {
        display: flex;
        flex-direction: column;

        .cancel {
          cursor: pointer;
          padding: 20px;
          text-transform: uppercase;
          text-decoration: underline;
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }
}
