.product-page-container-mobile {
  background: rgba(121, 121, 121, 0.082);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  .product-image-and-buy-option-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

    .product-details {
      // display: none;
    }
  }
}
