.product-showcase-container-mobile {
  padding: 50px;

  .product-showcase-header-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    h2,
    h3 {
      font-family: "Vollkorn SC", serif;
      opacity: 0.7;
      padding-bottom: 20px;
      text-align: center;
      cursor: pointer;
    }

    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 24px;
      text-decoration: underline;
    }
  }
  .product-showcase-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}
