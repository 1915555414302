.checkout-container-mobile {
  width: 95%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;
  text-decoration: none;
  font-family: "Vollkorn SC", serif;
  color: black;
  font-weight: 700;
  font-size: medium;
  margin-bottom: 100px;
  .checkout-header-mobile {
    width: 95%;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;

    .header-block {
      text-transform: uppercase;
      font-size: 12px;
      // width: 23%;

      &:nth-child(2) {
        margin: 0px;
      }
      &:nth-child(3) {
        margin: 0px;
      }
      &:last-child {
        width: 17%;
      }
    }
  }

  .shipping {
    // display: none;
    margin-top: 30px;
    margin-left: auto;
  }
  .total {
    // display: none;
    margin-top: 30px;
    margin-left: auto;
    font-size: 30px;
    margin-bottom: 50px;
  }
}
