.homepage-reviews-container-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgba(157, 3, 3, 0.664);
  width: 100vw;
  height: 400px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px;
  margin-top: -23.2vh;

  .title-text {
    font-size: 25px;
    position: absolute;
    margin-bottom: 200px;
    font-family: "Vollkorn SC", serif;
    font-weight: bolder;
  }

  .review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .review-text {
      font-size: 20px;
      // max-height: 50px;
      text-align: center;
      padding: 30px 5px;
      font-style: italic;
    }

    .stars {
    }

    .reviewer-text {
      font-family: "Mate", serif;
      font-weight: bolder;
      padding-top: 10px;
    }
  }
  .buttons {
    position: absolute;
    margin-top: 300px;

    .fontawesome-icon {
      padding: 7px;
      cursor: pointer;

      &.is-active {
        outline: black 3px solid;
        border-radius: 50%;
      }
    }
  }
}
