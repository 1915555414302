.shop-page {
  width: 100vw;
  height: auto;
  font-family: "Vollkorn SC", serif;

  .shop-page-filters {
    height: 300px;
    padding: 50px 75px;
    margin-bottom: 50px;
    color: white;
    background-color: rgba(0, 0, 0, 0.166);

    .filters-title-text {
      .filters-title {
        font-size: 2rem;
        padding: 10px 0;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.922);
      }
      .filters-subtitle {
        font-size: 3.2rem;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.922);
      }
    }

    .filters {
      padding: 50px 50px;
      width: 85%;
      display: flex;
      justify-content: space-between;
      position: absolute;

      .filter-one,
      .filter-two,
      .filter-three {
        .Collapsible {
          background-color: rgba(0, 0, 0, 0.922);
          padding: 0 60px;
        }

        //The content within the collaspable area
        .Collapsible__contentInner {
          padding: 40px;
          border: 1px solid rgba(0, 0, 0, 0.336);

          border-top: 0;

          p {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 20px;
            padding: 12px 0;
            margin-top: 5px;
            border: 1px solid rgba(0, 0, 0, 0.825);
            text-align: center;
            cursor: pointer;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .Collapsible__trigger {
          display: block;
          text-decoration: none;
          position: relative;
          border: 1px solid rgba(0, 0, 0, 0.336);
          padding: 10px 20px;
          background-color: rgba(0, 0, 0, 0.336);
          color: white;
          font-size: large;

          &:after {
            font-family: "FontAwesome";
            content: "\f107";
            position: absolute;
            right: 10px;
            top: 10px;
            display: block;
            transition: transform 300ms;
          }

          &.is-open {
            &:after {
              transform: rotateZ(180deg);
            }
          }

          &.is-disabled {
            opacity: 0.5;
            background-color: grey;
          }
        }

        .CustomTriggerCSS {
          background-color: lightcoral;
          transition: background-color 200ms ease;
        }

        .CustomTriggerCSS--open {
          background-color: darkslateblue;
        }

        .Collapsible__custom-sibling {
          padding: 5px;
          font-size: 12px;
          background-color: #cbb700;
          color: black;
        }
      }
    }
  }

  .shop-page-header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    h1 {
      font-size: 40px;
      text-transform: uppercase;
      text-shadow: 40px;
    }

    p {
      font-size: 20px;
      text-transform: capitalize;
      padding: 20px 0;
    }
  }

  .shop-section {
    padding-top: 50px;
    padding-bottom: 200px;
    .product-preview-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 50px;
    }
  }
}
