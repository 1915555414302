.product-materials-section-container-mobile {
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: rgba(36, 36, 36, 0.7);
  .right-side {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    text-align: center;
    color: white;
    h1 {
      font-size: 30px;
      font-weight: lighter;
      margin-bottom: 30px;
      margin-top: 15px;
      text-align: center;
    }

    h4 {
      font-size: 25px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      line-height: 25px;
      font-size: 18px;
    }
  }
}
