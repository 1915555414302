.product-showcase-container {
  padding: 100px;

  .product-showcase-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    h2,
    h3 {
      font-family: "Vollkorn SC", serif;
      opacity: 0.7;
      padding-bottom: 20px;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    h2 {
      font-size: 28px;
      color: black;
      text-decoration: none;
    }
    h3 {
      font-size: 24px;
      text-decoration: none;
      color: black;
    }
  }
  .product-showcase {
    display: flex;
    flex-direction: row;
    width: 100%;

    .product-preview-section {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
