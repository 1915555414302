.hero-container-mobile {
  display: flex;
  flex-direction: column-reverse;

  .hero-text-mobile {
    background: rgba(104, 96, 96, 0.973);
    padding: 100px 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2 {
      font-size: 35px;
      color: white;
      font-family: "Vollkorn SC", serif;
      margin-bottom: 20px;
    }

    p {
      font-size: 20px;
      margin-top: 30px;
      line-height: 1.5rem;
      color: white;
    }
  }
  .image-side-mobile {
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position-x: -20px;
    background-repeat: no-repeat;

    .shop-button-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      padding-bottom: 40px;
      gap: 10px;

      .shop-title-text {
        color: black;
        border: 2px black solid;
        padding: 5px;
        background: rgba(104, 96, 96, 0.4);
        color: white;
        text-align: center;
        .shop-title {
          font-size: 30px;
          width: 200px;
          font-family: "Vollkorn SC", serif;
        }
      }
    }
  }
}
