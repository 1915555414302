.about-us-page-container {
  .about-us-header {
    background-color: rgba(142, 142, 142, 0.3);
    h1 {
      font-size: 50px;
      text-transform: uppercase;
      padding: 50px;
      font-weight: 400;
    }
    .about-us-top-section {
      display: flex;
      padding: 0 50px;

      h2 {
        font-size: 30px;
        width: 60%;
        margin-top: auto;
        margin-bottom: auto;
        padding: 0 20px;
      }

      .about-us-top-section-image {
        width: 600px;
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0 20px;
      }
    }
  }
  .about-sections {
    display: flex;
    flex-direction: column;

    .about-section {
      hr {
        border-top: 10px solid #8c8b8b;
        width: 90%;
      }
      text-align: center;

      h3 {
        font-size: 25px;
      }

      p {
        width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding: 30px 0;
        font-size: 18px;
      }
    }
  }
}
