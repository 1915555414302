.checkout-complete-page-container {
  .thank-you-text {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 12.5%;
    text-align: center;

    h1 {
      font-size: 35px;
      text-transform: uppercase;
      padding: 20px 0;
    }

    p {
      font-size: 25px;
      text-transform: capitalize;
      padding: 40px 0;
    }
  }
}
