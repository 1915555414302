.header-container {
  padding: 20px 50px;
  width: 100%;
  background: white;
  z-index: 1;

  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */

  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-menu {
    display: flex;
    align-items: center;

    .header-link {
      padding: 0 8px;
      text-decoration: none;
      font-family: "Vollkorn SC", serif;
      color: black;
      font-weight: 700;
      font-size: large;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .logo {
    img {
      height: 40px;
      margin-left: 100px;
    }
  }

  .search-input {
    width: 300px;
  }
}
