.other-pieces-you-will-love-container-mobile {
  background: rgba(117, 116, 116, 0.877);
  padding: 5px 40px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 30px;
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
  }

  .product-preview-section {
    padding-top: 20px;
    padding-bottom: 50px;
  }
}
