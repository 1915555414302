.hero-container {
  width: 100vw;
  height: 100vh;
  .overlay {
    width: 100%;
    height: 100%;
    display: flex;

    .hero-text {
      width: 43%;
      background: rgba(104, 96, 96, 0.973);
      padding: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-size: 35px;
        color: white;
        font-family: "Vollkorn SC", serif;
        margin-top: 100px;
      }

      p {
        font-size: 20px;
        margin-top: 30px;
        padding: 0 100px 0px 0;
        line-height: 1.5rem;
        color: white;
      }
    }
    .image-side {
      width: 57%;
      height: 100%;
      background-size: cover;
      background-position-y: -200px;
      background-repeat: no-repeat;

      &.left {
        background-position-y: -200px;
      }

      &.right {
      }
    }
  }
}
