.terms-of-service-page-container {
  padding: 100px 200px;

  h1 {
    font-size: 35px;
    padding: 10px 0;
  }

  h2 {
    font-size: 25px;
    padding: 30px 0;
  }

  p {
    font-size: 18px;
    padding: 10px 0;
  }
}
