.affirm-page-container {
  padding: 50px 20px;

  .header {
    text-align: center;

    h1 {
      font-size: large;
    }

    p {
      font-size: medium;
    }
  }
}
