.faqs-page-container-mobile {
  padding: 20px 40px;
  text-align: center;

  h1 {
    font-size: 32px;
    padding: 20px 0;
  }

  h2 {
    font-size: 22px;
    padding: 30px 0;
  }

  p {
    font-size: 18px;
    padding: 10px 0;
  }
}
